<template>
  <Popup
    title="알림 발송 예약"
    :maxWidth="800"
    closeBtnText="취소"
    compeleteBtnText="등록"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete')">
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송목적</strong>
          <p class="desc_tbl">테스트 발송시 테스터로 등록된 유저에게만 발송됩니다.</p>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:200px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발송목적<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Radio
              :dataList="'alarm_test_register' | getSelectDataList"
              :value.sync="viewModel.model.isTest"/>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">타겟설정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:200px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>광고유무<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Radio
              :dataList="'alarm_ad_register' | getSelectDataList"
              :value.sync="viewModel.model.isAd"/>
          </td>
        </tr>
        <tr>
          <th>플랫폼<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Selectbox
              :dataList="'alarm_platform' | getSelectDataList"
              :value.sync="viewModel.model.platform" />
          </td>
        </tr>
        <tr>
          <th>회원구분<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Selectbox
              :dataList="'alarm_audience' | getSelectDataList"
              :value.sync="viewModel.model.audience" />
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">레몬트리 앱내 알림</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:200px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>알림 타이틀<IconSvg iconName="essential" :size="8" iconColor="#F04848" /><span class="txt_info">(채널 구분)</span></th>
          <td>
            <Selectbox
              :dataList="'alarm_chanel' | getSelectDataList"
              :value.sync="viewModel.model.chanel" />
          </td>
        </tr>
        <tr>
          <th>알림 본문<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="알림 본문을 입력해주세요"
              :value="viewModel.model.content"/>
          </td>
        </tr>
        <tr>
          <th>랜딩 URL</th>
          <td>
            <Input
              placeholder="랜딩 URL을 입력해주세요"
              :value="viewModel.model.landingUrl"/>
            <p class="desc_info">💡 별도의 URL이 지정되지 않은 경우, 알림에 링크 버튼이 노출되지 않습니다.</p>
          </td>
        </tr>
        <tr>
          <th>랜딩 버튼명<IconSvg v-if="viewModel.model.landingUrl" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="랜딩 버튼명을 입력해주세요"
              :value="viewModel.model.landingBtnText"/>
            <p class="desc_info">💡 랜딩 URL을 지정하신 경우, 버튼명도 지정해야 합니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">디바이스 푸시</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:200px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>푸시 타이틀</th>
          <td>
            <Input
              placeholder="푸시 타이틀을 입력해주세요"
              :value="viewModel.model.pushTitle"/>
          </td>
        </tr>
        <tr>
          <th>푸시 본문</th>
          <td>
            <Input
              placeholder="푸시 본문을 입력해주세요"
              :value="viewModel.model.pushContent"/>
          </td>
        </tr>
        <tr>
          <th>푸시 랜딩</th>
          <td>
            <p class="desc_info">💡 사용자가 푸시를 터치하면 앱내 알림에서 지정한 경로로 이동합니다.<br>지정한 경로가 없는 경우 레몬트리 앱이 실행됩니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송일정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:200px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>예약 일시<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <div class="group_form">
              <DatePicker
                :value.sync="viewModel.model.sendDate"/>
              <TimePicker
                :value.sync="viewModel.model.sendDate"/>
            </div>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';

import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import TimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/timePicker/TimePicker';
// viewModel
import AlarmDetailViewModel from '@/views/app/alarm/viewModel/AlarmDetailViewModel'

export default {
  name:'AlarmRegisterPopup',
  components:{
    Popup,
    TableView,
    IconSvg,
    Radio,
    Selectbox,
    Input,
    DatePicker,
    TimePicker
  },
  mounted(){
    // this.setDiffContent();
  },
  data(){
    return{
      viewModel: new AlarmDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.inner_td{display:flex;flex-wrap:nowrap;max-height:calc(100vh - 237px - 60px);padding-right:10px}
.area_diff,
.area_diff + .editor{flex:1 0 50%}
.area_diff + .editor{margin-left:10px}
.area_diff + .editor::v-deep .inner_editor{height:100%;max-height:inherit;box-sizing:border-box}

.area_diff{display:flex;flex-direction:column;box-sizing:border-box;border:3px solid #111;border-radius:8px}

.area_diff .tit_diff{display:block;padding:0 20px;font-weight:700;line-height:36px;border-bottom:3px solid #111;color:#111}
.inner_diff{flex:1 1;overflow-y:auto;border-radius:0 0 8px 8px;padding:20px;font-size:16px;line-height:24px;background-color:#eee;color:#111}
.inner_diff::v-deep h1,
.inner_diff::v-deep h2,
.inner_diff::v-deep .tit_comm,
.inner_diff::v-deep strong{font-weight:700}
.inner_diff::v-deep h1{font-size:28px;line-height:44px}
.inner_diff::v-deep h2{font-size:22px;line-height:40px}
.inner_diff::v-deep h3{font-weight:600;font-size:20px;line-height:34px}
.inner_diff::v-deep .tit_comm{font-size:20px}
.inner_diff::v-deep em{font-style:italic}
.inner_diff::v-deep a{color:#3b8aff;text-decoration:underline}
.inner_diff::v-deep img{display:block;width:calc(100% + 40px);margin:0 -20px}
.inner_diff::v-deep ul,
.inner_diff::v-deep ul > li{list-style:disc}
.inner_diff::v-deep ul{padding-left:20px;text-indent:-4px}
.inner_diff::v-deep ul > li > ul,
.inner_diff::v-deep ul > li > ul > li{list-style:circle}
.inner_diff::v-deep ul > li > ul > li > ul,
.inner_diff::v-deep ul > li > ul > li > ul > li{list-style:square}
.inner_diff::v-deep ol,
.inner_diff::v-deep ol > li{list-style:decimal}
.inner_diff::v-deep ol{padding-left:26px}
.inner_diff::v-deep ol > li > ol,
.inner_diff::v-deep ol > li > ol > li{list-style:lower-latin}
.inner_diff::v-deep ol > li > ol > li > ol,
.inner_diff::v-deep ol > li > ol > li > ol > li{list-style:lower-roman}
.inner_diff::v-deep ol > li > ol{padding-left:20px}
.inner_diff::v-deep p{min-height:24px}

.inner_diff::v-deep .txt_diff_remove,
.inner_diff::v-deep .txt_diff_remove *{background-color:#f00f0020}
.inner_diff::v-deep .txt_diff_add,
.inner_diff::v-deep .txt_diff_add *{background-color:#08f00020}
</style>