import router from '@/router';

export default class AlarmListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'REGERVATION', text:'알림예약' },
        { id: 'COMPLETE', text:'발송완료' },
      ],
      option:{
        isCount: false,
        queryKeyName:'status'
      },
      value:'REGERVATION'
    }
    this.deployData = {
      REGERVATION:{
        dataList: [],
        boardData : {
          title:'알림예약목록',
          drawDataList: [
            {
              title:'No.',
              width:'60px',
              value:'id',
            },
            {
              title:'테스트',
              width:'70px',
              value:'isTest',
              filter:{
                name:'convertIdToText',
                value:'alarm_test'
              },
              isAlignLeft: true
            },
            {
              title:'광고유무',
              width:'70px',
              value:'isAd',
              filter:{
                name:'convertIdToText',
                value:'alarm_ad'
              },
              isAlignLeft: true
            },
            {
              title:'플랫폼',
              width:'70px',
              value:'platform',
              isAlignLeft: true
            },
            {
              title:'회원구분',
              width:'70px',
              value:'audience',
              filter:{
                name:'convertIdToText',
                value:'alarm_audience'
              },
              isAlignLeft: true
            },
            {
              title:'본문',
              width:'',
              value:'content',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'예약일시',
              width:'140px',
              value:'sendDate',
            },
            {
              title:'상태',
              width:'90px',
              value:'status',
              filter:{
                name:'convertIdToText',
                value:'alarm_status'
              },
              badge: {
                badgeColorFilter:{
                  name:'convertIdToColor',
                  dataListName:'alarm_status'
                },
                badgeFill: false
              }
            },
            {
              title:'푸시유무',
              width:'70px',
              value:'isPush',
              filter:{
                name:'convertIdToText',
                value:'common_boolean'
              },
            },
          ],
          option:{
            isTotal: true,
            isSize: true
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      COMPLETE:{
        dataList: [],
        boardData : {
          title:'발송완료목록',
          drawDataList: [
            {
              title:'No.',
              width:'60px',
              value:'id',
            },
            {
              title:'테스트',
              width:'70px',
              value:'isTest',
              filter:{
                name:'convertIdToText',
                value:'alarm_test'
              },
              isAlignLeft: true
            },
            {
              title:'광고유무',
              width:'70px',
              value:'isAd',
              filter:{
                name:'convertIdToText',
                value:'alarm_ad'
              },
              isAlignLeft: true
            },
            {
              title:'플랫폼',
              width:'70px',
              value:'platform',
              isAlignLeft: true
            },
            {
              title:'회원구분',
              width:'70px',
              value:'audience',
              filter:{
                name:'convertIdToText',
                value:'alarm_audience'
              },
              isAlignLeft: true
            },
            {
              title:'본문',
              width:'',
              value:'content',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'예약일시',
              width:'140px',
              value:'sendDate',
            },
            {
              title:'푸시유무',
              width:'70px',
              value:'isPush',
              filter:{
                name:'convertIdToText',
                value:'common_boolean'
              },
            },
            {
              title:'푸시오픈/도달/발송',
              width:'180px',
              value:'status',
              valueCustom:[
                { 
                  type: 'text',
                  value: '<em class="tc_primary">'
                },
                {
                  type: 'value',
                  value:'pushOpen',
                },
                { 
                  type: 'text',
                  value: '</em>/'
                },
                {
                  type: 'value',
                  value:'pushReach',
                },
                { 
                  type: 'text',
                  value: '/'
                },
                {
                  type: 'value',
                  value:'pushSendTotal',
                },
              ],
            },
            {
              title:'푸시오픈율',
              width:'80px',
              value:'openPersent',
            },
          ],
          option:{
            isTotal: true,
            isSize: true
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      }
    };
    this.checkboxOnlyOne = false;
    this.selectedDataList = [];
    this.isAlarmRegisterPopup = false;
  }
  init(){
    this.getData();
  }
  onClickAdd(){
    router.push({ name: 'APP_ALARM_REGISTER' });
  }
  onClickCloseAlarmRegisterPopup(){
    this.isAlarmRegisterPopup = false;
  }
  onClickCompleteAlarmRegisterPopup(){
    this.isAlarmRegisterPopup = false;
  }
  onClickDeploy(){

  }
  onClickRow(rowId){
    router.push({ name: 'APP_ALARM_DETAIL', params: { status : this.tabData.value, id : rowId } });
  }
  getData(){
    this.deployData.REGERVATION.dataList = [
      { id:1, isTest:false, isAd: true, platform:'Android', audience:'PARENT_ONLY', content:'레몬트리 약관이 변경됩니다. 자세한 내용은 이용약관 메뉴를 확인해주세요', sendDate:'2022-03-12 23:00', status:'ING', isPush:true },
      { id:2, isTest:true, isAd: false, platform:'iOS', audience:'ALL', content:'전산 시스템 업그레이드 작업으로 인해 일시적으로 서비스 사용이 제한됩니다.', sendDate:'2022-05-07 10:00', status:'REGERVVATION', isPush:true },
      { id:3, isTest:false, isAd: false, platform:'ALL', audience:'CHILD_ONLY', content:'시스템 장애 보상을 위해 모든 유저에게 500원을 지급합니다.', sendDate:'2022-07-24 13:00', status:'REGERVVATION', isPush:false },
    ];
    this.deployData.REGERVATION.paginationData.totalCount = 3;
    this.deployData.REGERVATION.paginationData.totalPage = 1;
    this.deployData.COMPLETE.dataList = [
      { id:1, isTest:false, isAd: true, platform:'iOS', audience:'CHILD_ONLY', content:'전산 시스템 업그레이드 작업으로 인해 일시적으로 서비스 사용이 제한됩니다.', sendDate:'2022-02-11 23:00', status:'COMPLETE', isPush:true, pushSendTotal:200000, pushOpen:1000, pushReach:1000, openPersent:'100%' },
      { id:2, isTest:true, isAd: true, platform:'ALL', audience:'PARENT_ONLY', content:'전산 시스템 업그레이드 작업으로 인해 일시적으로 서비스 사용이 제한됩니다.', sendDate:'2022-04-12 10:00', status:'COMPLETE', isPush:true, pushSendTotal:200000, pushOpen:1000, pushReach:1000, openPersent:'100%' },
      { id:3, isTest:true, isAd: false, platform:'Android', audience:'ALL', content:'레몬트리 약관이 변경됩니다. 자세한 내용은 이용약관 메뉴를 확인해주세요', sendDate:'2022-05-24 13:00', status:'COMPLETE', isPush:false, pushSendTotal:200000, pushOpen:200000, pushReach:200000, openPersent:'100%' },
    ];
    this.deployData.COMPLETE.paginationData.totalCount = 3;
    this.deployData.COMPLETE.paginationData.totalPage = 1;
  }
}